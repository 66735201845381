<template>
    <main>
        <Navbar />
        <div class="main">
            <div class="container">
                <div class="overlap" :class="{'overlap-fixed': platform == 'mobile', 'overlap-hide': !overLap}">
                    <div class="mt-3" v-if="platform == 'mobile'">
                        <button class="btn btn-info btn-sm btn-block" @click="overLapToggle">
                            <i class="fas fa-filter mr-2"></i>{{ overLap ? 'Fechar filtros' : 'Abrir filtros' }}
                        </button>
                    </div><!-- /mt-3 -->
                    <div class="overlap-content">
                        <!-- admin -->
                        <template v-if="pipe == 3 || launch == 'sim'">
                            <div class="row ml-0 mt-3 mb-3 mr-0" v-show="users.length">
                                <div class="col pl-0" :class="{'pr-0': platform == 'mobile'}">
                                    <div id="virtual-select" :class="{'mb-3': platform == 'mobile'}"></div>
                                </div>
                                <div class="w-100" v-show="platform == 'mobile'"></div>
                                <div class="col pl-0" data-input-subtitle="Data incial" :class="{'pr-0': platform == 'mobile'}">
                                    <input class="form-control" :class="{'mb-3': platform == 'mobile'}" type="date" v-model="date1">
                                </div>
                                <div class="w-100" v-show="platform == 'mobile'"></div>
                                <div class="col pl-0" data-input-subtitle="Data final" :class="{'pr-0': platform == 'mobile'}">
                                    <input class="form-control" :class="{'mb-3': platform == 'mobile'}" type="date" v-model="date2">
                                </div>
                                <div class="w-100" v-show="platform == 'mobile'"></div>
                                <div class="col pl-0 pr-0">
                                    <button class="btn btn-warning btn-block" :class="{'btn-sm': platform == 'mobile'}" @click="search"><i class="fas fa-search mr-2"></i>Buscar</button>
                                </div><!-- /col -->
                            </div><!-- /row -->
                            <div class="row ml-0 mt-3 mb-3 mr-0" v-show="salesman.length">
                                <div class="col pl-0" :class="{'pr-0': platform == 'mobile'}">
                                    <div id="virtual-select-salesman" :class="{'mb-3': platform == 'mobile'}" data-style="form-control"></div>
                                </div>
                                <div class="w-100" v-show="platform == 'mobile'"></div>
                                <div class="col pl-0" :class="{'d-none': platform == 'mobile'}">
                                    <input class="form-control" type="text" placeholder="Pesquisar vendedor..." v-model="searchs">
                                </div>
                                <div class="w-100" v-show="platform == 'mobile'"></div>
                                <div class="col pl-0" :class="{'pr-0': platform == 'mobile'}">
                                    <div class="btn-group w-100">
                                        <button type="button" class="btn btn-block btn-danger dropdown-toggle" :class="{'btn-sm': platform == 'mobile'}" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <span><i class="fas fa-file-chart-line mr-2"></i>Relatório</span>
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right">
                                            <button class="dropdown-item" type="button" @click="selectDialog(1)"><i class="fas fa-print mr-2"></i>Todos</button>
                                            <button class="dropdown-item" type="button" @click="selectDialog(2)"><i class="fas fa-thumbs-up mr-2"></i>Positivo</button>
                                            <button class="dropdown-item" type="button" @click="selectDialog(3)"><i class="fas fa-thumbs-down mr-2"></i>Negativo</button>
                                            <template v-if="pipe == 3 || pay_accounts == 'sim'">
                                                <div class="dropdown-divider"></div>
                                                <button class="dropdown-item" type="button" @click="closeBoxSalesmanAll" :disabled="can_close_box_disabled">
                                                    <i class="fas fa-hand-holding-usd mr-2"></i>Prestar contas
                                                </button>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                                <div class="w-100" v-show="platform == 'mobile'"></div>
                                <div class="col pl-0 pr-0">
                                    <button class="btn btn-primary btn-block" :class="{'mt-3 btn-sm': platform == 'mobile'}" @click="releases">
                                        <i class="fas fa-hands-usd mr-2"></i>Fazer lançamento
                                    </button>
                                </div>
                            </div><!-- /row -->
                        </template>
                        <!-- gerente -->
                        <template v-else>
                            <div class="row ml-0 mt-3 mb-3 mr-0" v-show="users.length">
                                <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                                    <div id="virtual-select" :class="{'mb-3': platform == 'mobile'}"></div>
                                </div>
                                <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                                    <input class="form-control" :class="{'mb-3': platform == 'mobile'}" type="date" v-model="date1">
                                </div>
                                <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                                    <input class="form-control" :class="{'mb-3': platform == 'mobile'}" type="date" v-model="date2">
                                </div>
                                <div class="col pl-0 pr-0" :class="{'d-contents': platform == 'mobile'}">
                                    <button class="btn btn-warning btn-block" @click="search"><i class="fas fa-search mr-2"></i>Buscar</button>
                                </div><!-- /col -->
                            </div><!-- /row -->
                            <div class="row ml-0 mt-3 mb-3 mr-0" v-show="salesman.length">
                                <div class="col pl-0" :class="{'d-contents': platform == 'mobile'}">
                                    <div id="virtual-select-salesman" :class="{'mb-3': platform == 'mobile'}" data-style="form-control"></div>
                                </div>
                                <div class="col pl-0 pr-0" :class="{'d-none': platform == 'mobile'}">
                                    <input class="form-control" type="text" placeholder="Pesquisar vendedor..." v-model="searchs">
                                </div>
                            </div><!-- /row -->                    
                        </template>
                        <div class="mt-3 mb-3">
                            <div v-show="loading == true" class="alert alert-warning" role="alert">
                                <div class="alert-heading text-center mb-0">
                                    <div class="spinner-border mr-2 align-middle" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                    <span>Carregando, aguarde...</span>
                                </div>
                            </div><!-- /alert-warning -->
                            <div v-show="error.length > 0" class="alert alert-danger" role="alert">
                                <h4 class="alert-heading">Ops, ocorreu um problema!</h4>
                                <hr />
                                <p class="mb-0">{{error}}</p>
                            </div><!-- /alert-danger -->
                            <div v-show="users.length == 0 && nothing_found == true" class="alert alert-info" role="alert">
                                <h4 class="alert-heading">Ops, ocorreu um problema!</h4>
                                <hr />
                                <p class="mb-0">Não encontramos nenhum vendedor!</p>
                            </div><!-- /alert-danger -->
                        </div><!-- /mt-3 mb-3 -->
                    </div><!-- /overlap-content -->
                </div><!-- overlap -->
                <template v-if="filteredListSalesman.length">
                    <collection-cluster v-bind="collection" :items="filteredListSalesman" :style="{height: screen_innerHeight + 'px'}" :class="{'desktop pr-3': platform == 'desktop'}">
                        <div class="card mb-3" slot="item" slot-scope="{ cell, item }" :key="cell.index" :ref="item.id">
                            <div class="card-header">
                                <div class="row">
                                    <div class="text-truncate mt-1" :class="{'col-6': (pipe == 3 || pay_accounts == 'sim'), 'col': pay_accounts == 'nao'}">{{item.nome}}: {{item.endereco}}</div>
                                    <div class="col pr-0" v-if="pipe == 3 || pay_accounts == 'sim'">
                                        <template v-if="checkBalance(item.total_apuracao, item.total_premios, item.total_comissao, item.total_comissao_ao_vivo, item.retiradas, item.lancamentos) == 0">
                                            <button class="btn btn-sm btn-block btn-success" disabled>
                                                <template v-if="platform == 'desktop'">
                                                    <i class="fas fa-handshake mr-2"></i>Em dia
                                                </template>
                                                <template v-else>
                                                    <i class="fas fa-handshake"></i>
                                                </template>
                                            </button>
                                        </template>
                                        <template v-else>
                                            <button class="btn btn-sm btn-block btn-secondary" @click="closeBoxSalesman(item.id, (item.total_apuracao - item.total_premios - item.total_comissao - item.total_comissao_ao_vivo - item.retiradas) + item.lancamentos)" :disabled="can_close_box_disabled">
                                                <template v-if="platform == 'desktop'">
                                                    <i class="fas fa-hand-holding-usd mr-2"></i>Prestar contas
                                                </template> 
                                                <template v-else>
                                                    <i class="fas fa-hand-holding-usd"></i>
                                                </template>
                                            </button>
                                        </template>
                                    </div>
                                    <div :class="{'col': (pipe == 3 || pay_accounts == 'sim'), 'col-auto': pay_accounts == 'nao'}">
                                        <div class="btn-group w-100">
                                            <button type="button" class="btn btn-sm btn-block btn-info dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <span v-if="platform == 'desktop'">Mais</span>
                                            </button>
                                            <div class="dropdown-menu dropdown-menu-right">
                                                <h6 class="dropdown-header">Relatório</h6>
                                                <div class="dropdown-divider"></div>
                                                <button class="dropdown-item" type="button" @click="printReport(item.id)"><i class="fas fa-print mr-2"></i>Imprimir</button>
                                                <button class="dropdown-item" type="button" @click="savePDF(item.id)"><i class="far fa-file-pdf mr-2"></i>Salvar PDF</button>
                                                <button class="dropdown-item" type="button" @click="saveImage(item.id)"><i class="fas fa-file-image mr-2"></i>Salvar imagem</button>
                                            </div>
                                        </div>                                    
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="row pt-2 pb-2 bg-f1f1f1 rounded">
                                    <div class="col text-truncate">Apurou</div>
                                    <div class="col text-truncate">{{item.total_apuracao | formatCoin}}</div>
                                </div><!-- /row -->
                                <div class="row pt-2 pb-2">
                                    <div class="col text-truncate">Qtd. Apostas</div>
                                    <div class="col text-truncate">{{item.num_apostas | formatValue}}</div>
                                </div><!-- /row -->
                                <div class="row pt-2 pb-2 bg-f1f1f1 rounded">
                                    <div class="col text-truncate">Qtd. Ganhadores</div>
                                    <div class="col text-truncate">{{item.num_premios | formatValue}}</div>
                                </div><!-- /row -->
                                <div class="row pt-2 pb-2">
                                    <div class="col text-truncate">Qtd. Apostas abertas</div>
                                    <div class="col text-truncate">{{item.num_apostas_abertas | formatValue}}</div>
                                </div><!-- /row -->
                                <div class="row pt-2 pb-2 bg-f1f1f1 rounded">
                                    <div class="col text-truncate">Entradas em aberto</div>
                                    <div class="col text-truncate">{{item.total_apostas_abertas | formatCoin}}</div>
                                </div><!-- /row -->
                                <div class="row pt-2 pb-2">
                                    <div class="col text-truncate">Saldo em prêmios</div>
                                    <div class="col text-truncate">{{item.total_premios | formatCoin}}</div>
                                </div><!-- /row -->
                                <div class="row pt-2 pb-2 bg-f1f1f1 rounded">
                                    <div class="col text-truncate">{{platform == 'desktop' ? 'Saldo em comissões (pré-jogo)' : 'Comissões (Pré-jogo'}}</div>
                                    <div class="col text-truncate">{{item.total_comissao | formatCoin}}</div>
                                </div><!-- /row -->
                                <div class="row pt-2 pb-2">
                                    <div class="col text-truncate">{{platform == 'desktop' ? 'Saldo em comissões (ao-vivo)' : 'Comissões (AV)'}}</div>
                                    <div class="col text-truncate">{{item.total_comissao_ao_vivo | formatCoin}}</div>
                                </div><!-- /row -->
                                <div class="row pt-2 pb-2 bg-f1f1f1 rounded">
                                    <div class="col text-truncate">{{platform == 'desktop' ? 'Saldo em comissões (geral)' : 'Comissões (Geral'}}</div>
                                    <div class="col text-truncate">{{item.total_comissao + item.total_comissao_ao_vivo | formatCoin}}</div>
                                </div><!-- /row -->
                                <div class="row pt-2 pb-2">
                                    <div class="col text-truncate">{{platform == 'desktop' ? 'Saldo em lançamentos' : 'S. Lançamentos'}}</div>
                                    <div class="col text-truncate">{{item.lancamentos | formatCoin}}</div>
                                </div><!-- /row -->
                                <div class="row pt-2 pb-2 bg-f1f1f1 rounded">
                                    <div class="col text-truncate">{{platform == 'desktop' ? 'Saldo em retiradas' : 'S. Retiradas'}}</div>
                                    <div class="col text-truncate">{{item.retiradas | formatCoin}}</div>
                                </div><!-- /row -->
                                <div class="row pt-2 pb-2">
                                    <div class="col text-truncate">Saldo líquido</div>
                                    <div class="col text-truncate" :class="positiveOrNegative((item.total_apuracao - item.total_premios - item.total_comissao - item.total_comissao_ao_vivo))">{{item.total_apuracao - item.total_premios - item.total_comissao - item.total_comissao_ao_vivo | formatCoin}}</div>
                                </div><!-- /row -->
                                <div class="row pt-2 pb-2 bg-f1f1f1 rounded">
                                    <div class="col text-truncate">Saldo caixa</div>
                                    <div class="col text-truncate">{{checkBalance(item.total_apuracao, item.total_premios, item.total_comissao, item.total_comissao_ao_vivo, item.retiradas, item.lancamentos) | formatCoin}}</div>
                                </div><!-- /row -->
                            </div><!-- /card-body -->
                        </div><!-- /card -->
                    </collection-cluster>
                </template>
            </div><!-- /container -->
        </div><!-- /main -->
      <Loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true"></Loading>
    </main>
</template>

<script>
import {api} from '../api'
import {jsPDF} from 'jspdf'
import Navbar from './Navbar'
import Swal from 'sweetalert2'
import moment from 'moment-timezone'
import Loading from 'vue-loading-overlay'
import * as rasterizeHTML from 'rasterizehtml'
import CollectionCluster from 'vue-collection-cluster'
import 'vue-loading-overlay/dist/vue-loading.css'
import 'virtual-select-plugin/dist/virtual-select.min.css'
import 'virtual-select-plugin/dist/virtual-select.min.js'

const today = moment().tz('America/Recife').format('YYYY-MM-DD');
const heightAdjustMobile = 145;
const heightAdjustDesktop = 206;
const heightAdjust = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? heightAdjustMobile : heightAdjustDesktop;

export default {
    data() {
        return {
            pipe: 0,
            error: '',
            users: [],
            user: 'all',
            date1: today,
            date2: today,
            salesman: [],
            searchs: '',
            launch: 'nao',
            overLap: true,
            pay_accounts: 'nao',
            filter_az_active: '',
            loading: false,
            isLoading: false,
            nothing_found: false,
            has_big_select: true,
            can_close_box_disabled: true,
            collection: {
                heightType: 'automatic',
                // itemHeight: 742
            },
            dbUsers: {},
            screen_innerHeight: window.innerHeight - heightAdjust,
            platform: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 'mobile' : 'desktop',
            alphabetic: ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z']
        }
    },
    components: {
        Navbar,
        Loading,
        CollectionCluster
    },
    methods: {
        search() {

            const self = this;
            const url = self.user == 'all' ? `dashboard/cash/salesman/${self.date1}/between/${self.date2}/all` : `dashboard/cash/salesman/${self.date1}/between/${self.date2}/user/${self.user}`;

            if (self.date1 == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe a data inicial!',
                    'warning'
                );
            } else if (self.date2 == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe a data final!',
                    'warning'
                );
            } else {
                
                self.searchs = '';
                self.salesman = [];
                self.isLoading = true;
                self.accountabilityReleased();

                api.get(url).then((response) => {
                    switch (response.data.result) {
                        case 'success':
                            self.overLapToggle();
                            self.pay_accounts = response.data.permits.prestar_contas;
                            self.salesman = self.orderSalesman(response.data.salesman);
                        break;
                        case 'nothing_found':
                            Swal.fire(
                                'Atenção!',
                                'Não encontramos nenhum relatório financeiro!',
                                'warning'
                            )
                        break;
                        default:
                            Swal.fire(
                                'Atenção!',
                                response.data.message,
                                'warning'
                            )
                    }
                }).catch((error) => {
                    try {
                        Swal.fire(
                            'Falha',
                            self.network_erros[error.status]['message'],
                            'error'
                        );
                    } catch(e) {
                        Swal.fire(
                            'Falha',
                            self.network_erros[408]['message'],
                            'error'
                        );
                    }
                }).finally(() => {
                    self.isLoading = false;
                });
            }
        },
        orderSalesman(salesman) {
            return Object.keys(salesman).map((key) => {
                return salesman[key];
            }).sort((a, b) => {
                return this.removeAccents(a.nome).localeCompare(this.removeAccents(b.nome), 'fr', {
                    ignorePunctuation: true
                });
            }).filter((item) => item.total_apuracao > 0);
        },
        closeBoxSalesmanAll() {
            
            let report = [];
            let block = false;

            for (const item of this.salesman) {
                if (item.num_apostas_abertas > 0) {
                    block = true;
                    break;
                }

                let vendedor = this.dbUsers[item.id] || {credito_limite: 0, credito_simples_limite: 0, gerente_id: 0};
                let valor = (parseFloat(item.total_apuracao) - parseFloat(item.total_premios) - parseFloat(item.total_comissao) - parseFloat(item.total_comissao_ao_vivo) - parseFloat(item.retiradas)) + parseFloat(item.lancamentos);
                
                if (valor != 0) {
                    report.push({
                        _id: item.id,
                        valor: valor,
                        vendedor: item.nome,
                        usuario: item.usuario,
                        endereco: item.endereco,
                        gerente_id: vendedor.gerente_id,
                        credito_limite: vendedor.credito_limite,
                        credito_simples_limite: vendedor.credito_simples_limite
                    });
                }
            }

            if (block) {
                Swal.fire(
                    'Atenção',
                    'Ainda existe caixa com apostas abertas, aguarde até que todas as apostas sejam finalizadas, para poder prosseguir com a prestação de contas.',
                    'warning'
                );
            } else if (report.length == 0 || this.can_close_box_disabled) {
                Swal.fire(
                    'Atenção',
                    'Aprestação de contas da semana já foi realizada!',
                    'warning'
                );
            } else {

                const self = this;

                Swal.fire({
                    title: 'Prestação de contas',
                    html: 'Você deseja mesmo <strong>prestar contas</strong> de todos os vendedores?<hr/><span class="text-danger font-bold">Lembrando que após confirmar, o caixa será fechado e os créditos serão renovados automaticamente.</span>',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Sim'
                }).then((result) => {

                    if (result.value) {

                        self.isLoading = true;

                        api.post('dashboard/close-box', {
                            salesman: report,
                            date: self.date2
                        }).then((response) => {
                            switch(response.data.result) {
                                case 'success':
                                    // self.updateSalesman();
                                    self.can_close_box_disabled = true;
                                    Swal.fire(
                                        'Parabéns!',
                                        'Todos os caixas foram fechados!',
                                        'success'
                                    );
                                break;
                                default:
                                    Swal.fire(
                                        'Falha',
                                        response.data.message,
                                        'error'
                                    );
                                break;
                            }
                        }).catch((error) => {
                            try {
                                Swal.fire(
                                    'Falha',
                                    self.network_erros[error.status]['message'],
                                    'error'
                                );
                            } catch(e) {
                                Swal.fire(
                                    'Falha',
                                    self.network_erros[408]['message'],
                                    'error'
                                );
                            }
                        }).finally(() => {
                            self.isLoading = false;
                        });
                    }
                }); 
            }
        },
        closeBoxSalesman(id, value) {

            try {

                const self = this;
                const salesman = self.salesman.find((item) => item.id == id);

                if (self.can_close_box_disabled) {
                    return;
                } else if (salesman.num_apostas_abertas > 0) {
                    Swal.fire(
                        'Atenção',
                        `O caixa do vendedor "<b>${salesman.nome}</b>". <br /> Ainda tem (<b>${salesman.num_apostas_abertas}</b>) ${salesman.num_apostas_abertas > 1 ? 'apostas abertas' : 'aposta aberta'}. <hr /> O fechamento de caixa só é possível quando não tem nenhuma aposta aberta! `,
                        'warning'
                    );
                } else {
                    Swal.fire({
                        title: 'Prestar contas',
                        html: `Você deseja mesmo prestar contas <br /> do vendendor "<b>${salesman.nome}</b>" ? <p class="mt-3 pt-3 border-top">Valor da transação: <b>${self.formatCoin(value).replace('-','')}</b> <br />  Data da prestação: <b>${self.formatDate(self.date2)}</b></p><p class="mt-3 pt-3 border-top">Tipo de prestação: <b class="${value < 0 ? 'text-success' : 'text-danger'}">${value < 0 ? 'Envio de dinheiro' : 'Retirada de dinheiro'}</b></p>`,
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        cancelButtonText: 'Cancelar',
                        confirmButtonText: 'Sim'
                    }).then((result) => {

                        if (result.value) {

                            self.isLoading = true;

                            api.put(`dashboard/close-box/${id}/${value}/${self.date2}`).then((response) => {
                                switch(response.data.result) {
                                    case 'success':
                                        self.updateSalesmanByID(id, value);
                                        Swal.fire(
                                            'Parabéns!',
                                            `O caixa do vendedor ${salesman.nome} foi fechado!`,
                                            'success'
                                        );
                                    break;
                                    default:
                                        Swal.fire(
                                            'Falha',
                                            response.data.message,
                                            'error'
                                        );
                                    break;
                                }
                            }).catch((error) => {
                                try {
                                    Swal.fire(
                                        'Falha',
                                        self.network_erros[error.status]['message'],
                                        'error'
                                    );
                                } catch(e) {
                                    Swal.fire(
                                        'Falha',
                                        self.network_erros[408]['message'],
                                        'error'
                                    );
                                }
                            }).finally(() => {
                                self.isLoading = false;
                            });
                        }
                    }); 
                }
            } 
            catch(e) 
            {
                Swal.fire(
                    'Falha',
                    'Ocorreu um erro, atualize a página!',
                    'error'
                );
            }
        },
        printReport(id) {
            
            const self = this;
            const print_iframe = document.createElement('iframe');
            const date1 = moment(self.date1).format('DD/MM/YYYY');
            const date2 = moment(self.date2).format('DD/MM/YYYY');

            try {

                const salesman = self.salesman.find((item) => item.id == id);

                const html = `
                    <html>
                        <head>
                            <style>body{font-family:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"}.table{width:100%;border-collapse:collapse}.table td {padding:10px}</style>
                        </head>
                    <body>
                        <br /> 
                            <center><strong>Relatório</strong> <br /> Do dia ${date1} até ${date2}</center>
                        <br />
                        <table class="table" border="1">
                            <tbody>
                                <tr>
                                    <td colspan="12" align="center" bgcolor="#ddd">
                                        <strong>
                                            <p style="margin-bottom:5px">${salesman.nome.toUpperCase()}</p>
                                        </strong>
                                        <hr />
                                        <p>${salesman.endereco}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Apurou</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.total_apuracao)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Qtd. Apostas</td>
                                    <td align="center" width="50%">${self.formatValue(salesman.num_apostas)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Qtd. Ganhadores</td>
                                    <td align="center" width="50%">${self.formatValue(salesman.num_premios)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Qtd. Apostas abertas</td>
                                    <td align="center" width="50%">${self.formatValue(salesman.num_apostas_abertas)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Entradas em aberto</td>
                                    <td align="center" width="50%">${self.formatValue(salesman.total_apostas_abertas)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em prêmios</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.total_premios)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em comissões (pré-jogo)</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.total_comissao)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em comissões (ao-vivo)</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.total_comissao_ao_vivo)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em comissões (geral)</td>
                                    <td align="center" width="50%">${self.formatCoin(parseFloat(salesman.total_comissao) + parseFloat(salesman.total_comissao_ao_vivo))}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em lançamentos</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.lancamentos)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em retiradas</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.retiradas)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo líquido</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.total_apuracao - salesman.total_premios - salesman.total_comissao - salesman.total_comissao_ao_vivo)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo caixa</td>
                                    <td align="center" width="50%">${self.formatCoin(self.checkBalance(salesman.total_apuracao, salesman.total_premios, salesman.total_comissao, salesman.total_comissao_ao_vivo, salesman.retiradas, salesman.lancamentos))}</td>
                                </tr>
                            </tbody>
                        </table>
                        <h5>
                            <center>${document.location.origin}</center>
                        </h5>
                    </body>
                    </html>
                `;

                print_iframe.width = '0px';
                print_iframe.height = '0px';
                print_iframe.src = 'about:blank';
                print_iframe.style.visibility = 'hidden';

                print_iframe.onload = function() {
                    let print_script_tag = print_iframe.contentWindow.document.createElement("script");
                        print_script_tag.type = "text/javascript";
        
                    let print_script = print_iframe.contentWindow.document.createTextNode('function Print(){ window.focus(); window.print(); }');
                        print_script_tag.appendChild(print_script);

                    print_iframe.contentWindow.document.body.innerHTML = html;
                    print_iframe.contentWindow.document.body.appendChild(print_script_tag);
                    print_iframe.contentWindow.Print();
                };
                    
                document.body.appendChild(print_iframe);
                print_iframe.remove();

            } catch(e) {
                Swal.fire(
                    'Falha',
                    'Não foi possível imprimir o relatório!',
                    'error'
                );
            }
        },
        printReportByManager(type) {
            
            let html = ``;
            let self = this;
            let print_iframe = document.createElement('iframe');

            if (self.salesman.length == 0) {
                Swal.fire(
                    'Falha',
                    'Não encontramos nenhum vendedor disponível!',
                    'error'
                );
            } else {

                for (let salesman of self.salesman) {

                    let status = self.positiveOrNegative((salesman.total_apuracao - salesman.total_premios - salesman.total_comissao - salesman.total_comissao_ao_vivo));

                    if (type == 1) {
                        html += `
                        <table class="table" border="1">
                            <tbody>
                                <tr>
                                    <td colspan="12" align="center" bgcolor="#ddd">
                                        <strong>
                                            <p style="margin-bottom:5px">${salesman.nome.toUpperCase()}</p>
                                        </strong>
                                        <hr />
                                        <p>${salesman.endereco}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Apurou</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.total_apuracao)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Qtd. Apostas</td>
                                    <td align="center" width="50%">${self.formatValue(salesman.num_apostas)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Qtd. Ganhadores</td>
                                    <td align="center" width="50%">${self.formatValue(salesman.num_premios)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Qtd. Apostas abertas</td>
                                    <td align="center" width="50%">${self.formatValue(salesman.num_apostas_abertas)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Entradas em aberto</td>
                                    <td align="center" width="50%">${self.formatValue(salesman.total_apostas_abertas)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em prêmios</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.total_premios)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em comissões (pré-jogo)</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.total_comissao)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em comissões (ao-vivo)</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.total_comissao_ao_vivo)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em comissões (geral)</td>
                                    <td align="center" width="50%">${self.formatCoin(parseFloat(salesman.total_comissao) + parseFloat(salesman.total_comissao_ao_vivo))}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em lançamentos</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.lancamentos)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em retiradas</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.retiradas)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo líquido</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.total_apuracao - salesman.total_premios - salesman.total_comissao - salesman.total_comissao_ao_vivo)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo caixa</td>
                                    <td align="center" width="50%">${self.formatCoin(self.checkBalance(salesman.total_apuracao, salesman.total_premios, salesman.total_comissao, salesman.total_comissao_ao_vivo, salesman.retiradas, salesman.lancamentos))}</td>
                                </tr>
                            </tbody>
                        </table>`;
                    } 
                    else
                    {
                        if (type == 2 && status == 'text-success') {
                            html += `
                            <table class="table" border="1">
                                <tbody>
                                    <tr>
                                        <td colspan="12" align="center" bgcolor="#ddd">
                                            <strong>
                                                <p style="margin-bottom:5px">${salesman.nome.toUpperCase()}</p>
                                            </strong>
                                            <hr />
                                            <p>${salesman.endereco}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Apurou</td>
                                        <td align="center" width="50%">${self.formatCoin(salesman.total_apuracao)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Qtd. Apostas</td>
                                        <td align="center" width="50%">${self.formatValue(salesman.num_apostas)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Qtd. Ganhadores</td>
                                        <td align="center" width="50%">${self.formatValue(salesman.num_premios)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Qtd. Apostas abertas</td>
                                        <td align="center" width="50%">${self.formatValue(salesman.num_apostas_abertas)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Entradas em aberto</td>
                                        <td align="center" width="50%">${self.formatValue(salesman.total_apostas_abertas)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Saldo em prêmios</td>
                                        <td align="center" width="50%">${self.formatCoin(salesman.total_premios)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Saldo em comissões (pré-jogo)</td>
                                        <td align="center" width="50%">${self.formatCoin(salesman.total_comissao)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Saldo em comissões (ao-vivo)</td>
                                        <td align="center" width="50%">${self.formatCoin(salesman.total_comissao_ao_vivo)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Saldo em comissões (geral)</td>
                                        <td align="center" width="50%">${self.formatCoin(parseFloat(salesman.total_comissao) + parseFloat(salesman.total_comissao_ao_vivo))}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Saldo em lançamentos</td>
                                        <td align="center" width="50%">${self.formatCoin(salesman.lancamentos)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Saldo em retiradas</td>
                                        <td align="center" width="50%">${self.formatCoin(salesman.retiradas)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Saldo líquido</td>
                                        <td align="center" width="50%">${self.formatCoin(salesman.total_apuracao - salesman.total_premios - salesman.total_comissao - salesman.total_comissao_ao_vivo)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Saldo caixa</td>
                                        <td align="center" width="50%">${self.formatCoin(self.checkBalance(salesman.total_apuracao, salesman.total_premios, salesman.total_comissao, salesman.total_comissao_ao_vivo, salesman.retiradas, salesman.lancamentos))}</td>
                                    </tr>
                                </tbody>
                            </table>`;
                        } else if (type == 3 &&  status == 'text-danger') {
                            html += `
                            <table class="table" border="1">
                                <tbody>
                                    <tr>
                                        <td colspan="12" align="center" bgcolor="#ddd">
                                            <strong>
                                                <p style="margin-bottom:5px">${salesman.nome.toUpperCase()}</p>
                                            </strong>
                                            <hr />
                                            <p>${salesman.endereco}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Apurou</td>
                                        <td align="center" width="50%">${self.formatCoin(salesman.total_apuracao)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Qtd. Apostas</td>
                                        <td align="center" width="50%">${self.formatValue(salesman.num_apostas)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Qtd. Ganhadores</td>
                                        <td align="center" width="50%">${self.formatValue(salesman.num_premios)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Qtd. Apostas abertas</td>
                                        <td align="center" width="50%">${self.formatValue(salesman.num_apostas_abertas)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Entradas em aberto</td>
                                        <td align="center" width="50%">${self.formatValue(salesman.total_apostas_abertas)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Saldo em prêmios</td>
                                        <td align="center" width="50%">${self.formatCoin(salesman.total_premios)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Saldo em comissões (pré-jogo)</td>
                                        <td align="center" width="50%">${self.formatCoin(salesman.total_comissao)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Saldo em comissões (ao-vivo)</td>
                                        <td align="center" width="50%">${self.formatCoin(salesman.total_comissao_ao_vivo)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Saldo em comissões (geral)</td>
                                        <td align="center" width="50%">${self.formatCoin(parseFloat(salesman.total_comissao) + parseFloat(salesman.total_comissao_ao_vivo))}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Saldo em lançamentos</td>
                                        <td align="center" width="50%">${self.formatCoin(salesman.lancamentos)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Saldo em retiradas</td>
                                        <td align="center" width="50%">${self.formatCoin(salesman.retiradas)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Saldo líquido</td>
                                        <td align="center" width="50%">${self.formatCoin(salesman.total_apuracao - salesman.total_premios - salesman.total_comissao - salesman.total_comissao_ao_vivo)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Saldo caixa</td>
                                        <td align="center" width="50%">${self.formatCoin(self.checkBalance(salesman.total_apuracao, salesman.total_premios, salesman.total_comissao, salesman.total_comissao_ao_vivo, salesman.retiradas, salesman.lancamentos))}</td>
                                    </tr>
                                </tbody>
                            </table>`;
                        }
                    }
                }

                if (!html) {
                    Swal.fire(
                        'Falha',
                        'Nenhum relatório disponível para a opção escolhida!',
                        'error'
                    );
                } else {
                    print_iframe.width = '0px';
                    print_iframe.height = '0px';
                    print_iframe.src = 'about:blank';
                    print_iframe.style.visibility = 'hidden';

                    print_iframe.onload = function() {
                        let print_script_tag = print_iframe.contentWindow.document.createElement("script");
                            print_script_tag.type = "text/javascript";
            
                        let print_script = print_iframe.contentWindow.document.createTextNode('function Print(){ window.focus(); window.print(); }');
                            print_script_tag.appendChild(print_script);

                        print_iframe.contentWindow.document.body.innerHTML = `<html>
                            <head>
                                <style>*{margin:0;padding:0}html{width:100%;height:100%}body{padding:20px;background:#fff;color:#000;font-family:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";}.table{width:100%;border-collapse:collapse}.table{margin-bottom:10px}.table td {padding:10px}</style>
                                </head>
                            <body class="content">
                                ${html}
                            </body>
                        </html>`;
                        print_iframe.contentWindow.document.body.appendChild(print_script_tag);
                        print_iframe.contentWindow.Print();
                    };
                        
                    document.body.appendChild(print_iframe);
                    print_iframe.remove();
                }
            }
        },
        pdfReportByManager(type) {
            
            let html = ``;
            let self = this;
            let doc = new jsPDF('p','pt','a4');
            let iframe = document.createElement('iframe');

            if (self.salesman.length == 0) {
                Swal.fire(
                    'Falha',
                    'Não encontramos nenhum vendedor disponível!',
                    'error'
                );
            } else {

                for (let salesman of self.salesman) {

                    let status = self.positiveOrNegative((salesman.total_apuracao - salesman.total_premios - salesman.total_comissao - salesman.total_comissao_ao_vivo));

                    if (type == 1) {
                        html += `
                        <table class="table" border="1" width="100%">
                            <tbody>
                                <tr>
                                    <td colspan="12" align="center" bgcolor="#ddd">
                                        <strong>
                                            <p style="margin-bottom:5px">${salesman.nome.toUpperCase()}</p>
                                        </strong>
                                        <hr />
                                        <p>${salesman.endereco}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Apurou</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.total_apuracao)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Qtd. Apostas</td>
                                    <td align="center" width="50%">${self.formatValue(salesman.num_apostas)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Qtd. Ganhadores</td>
                                    <td align="center" width="50%">${self.formatValue(salesman.num_premios)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Qtd. Apostas abertas</td>
                                    <td align="center" width="50%">${self.formatValue(salesman.num_apostas_abertas)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Entradas em aberto</td>
                                    <td align="center" width="50%">${self.formatValue(salesman.total_apostas_abertas)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em prêmios</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.total_premios)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em comissões (pré-jogo)</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.total_comissao)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em comissões (ao-vivo)</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.total_comissao_ao_vivo)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em comissões (geral)</td>
                                    <td align="center" width="50%">${self.formatCoin(parseFloat(salesman.total_comissao) + parseFloat(salesman.total_comissao_ao_vivo))}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em lançamentos</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.lancamentos)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em retiradas</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.retiradas)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo líquido</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.total_apuracao - salesman.total_premios - salesman.total_comissao - salesman.total_comissao_ao_vivo)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo caixa</td>
                                    <td align="center" width="50%">${self.formatCoin(self.checkBalance(salesman.total_apuracao, salesman.total_premios, salesman.total_comissao, salesman.total_comissao_ao_vivo, salesman.retiradas, salesman.lancamentos))}</td>
                                </tr>
                            </tbody>
                        </table>`;
                    } 
                    else
                    {
                        if (type == 2 && status == 'text-success') {
                            html += `
                            <table class="table" border="1" width="100%">
                                <tbody>
                                    <tr>
                                        <td colspan="12" align="center" bgcolor="#ddd">
                                            <strong>
                                                <p style="margin-bottom:5px">${salesman.nome.toUpperCase()}</p>
                                            </strong>
                                            <hr />
                                            <p>${salesman.endereco}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Apurou</td>
                                        <td align="center" width="50%">${self.formatCoin(salesman.total_apuracao)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Qtd. Apostas</td>
                                        <td align="center" width="50%">${self.formatValue(salesman.num_apostas)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Qtd. Ganhadores</td>
                                        <td align="center" width="50%">${self.formatValue(salesman.num_premios)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Qtd. Apostas abertas</td>
                                        <td align="center" width="50%">${self.formatValue(salesman.num_apostas_abertas)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Entradas em aberto</td>
                                        <td align="center" width="50%">${self.formatValue(salesman.total_apostas_abertas)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Saldo em prêmios</td>
                                        <td align="center" width="50%">${self.formatCoin(salesman.total_premios)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Saldo em comissões (pré-jogo)</td>
                                        <td align="center" width="50%">${self.formatCoin(salesman.total_comissao)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Saldo em comissões (ao-vivo)</td>
                                        <td align="center" width="50%">${self.formatCoin(salesman.total_comissao_ao_vivo)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Saldo em comissões (geral)</td>
                                        <td align="center" width="50%">${self.formatCoin(parseFloat(salesman.total_comissao) + parseFloat(salesman.total_comissao_ao_vivo))}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Saldo em lançamentos</td>
                                        <td align="center" width="50%">${self.formatCoin(salesman.lancamentos)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Saldo em retiradas</td>
                                        <td align="center" width="50%">${self.formatCoin(salesman.retiradas)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Saldo líquido</td>
                                        <td align="center" width="50%">${self.formatCoin(salesman.total_apuracao - salesman.total_premios - salesman.total_comissao - salesman.total_comissao_ao_vivo)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Saldo caixa</td>
                                        <td align="center" width="50%">${self.formatCoin(self.checkBalance(salesman.total_apuracao, salesman.total_premios, salesman.total_comissao, salesman.total_comissao_ao_vivo, salesman.retiradas, salesman.lancamentos))}</td>
                                    </tr>
                                </tbody>
                            </table>`;
                        } else if (type == 3 &&  status == 'text-danger') {
                            html += `
                            <table class="table" border="1">
                                <tbody>
                                    <tr>
                                        <td colspan="12" align="center" bgcolor="#ddd">
                                            <strong>
                                                <p style="margin-bottom:5px">${salesman.nome.toUpperCase()}</p>
                                            </strong>
                                            <hr />
                                            <p>${salesman.endereco}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Apurou</td>
                                        <td align="center" width="50%">${self.formatCoin(salesman.total_apuracao)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Qtd. Apostas</td>
                                        <td align="center" width="50%">${self.formatValue(salesman.num_apostas)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Qtd. Ganhadores</td>
                                        <td align="center" width="50%">${self.formatValue(salesman.num_premios)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Qtd. Apostas abertas</td>
                                        <td align="center" width="50%">${self.formatValue(salesman.num_apostas_abertas)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Entradas em aberto</td>
                                        <td align="center" width="50%">${self.formatValue(salesman.total_apostas_abertas)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Saldo em prêmios</td>
                                        <td align="center" width="50%">${self.formatCoin(salesman.total_premios)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Saldo em comissões (pré-jogo)</td>
                                        <td align="center" width="50%">${self.formatCoin(salesman.total_comissao)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Saldo em comissões (ao-vivo)</td>
                                        <td align="center" width="50%">${self.formatCoin(salesman.total_comissao_ao_vivo)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Saldo em comissões (geral)</td>
                                        <td align="center" width="50%">${self.formatCoin(parseFloat(salesman.total_comissao) + parseFloat(salesman.total_comissao_ao_vivo))}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Saldo em lançamentos</td>
                                        <td align="center" width="50%">${self.formatCoin(salesman.lancamentos)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Saldo em retiradas</td>
                                        <td align="center" width="50%">${self.formatCoin(salesman.retiradas)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Saldo líquido</td>
                                        <td align="center" width="50%">${self.formatCoin(salesman.total_apuracao - salesman.total_premios - salesman.total_comissao - salesman.total_comissao_ao_vivo)}</td>
                                    </tr>
                                    <tr>
                                        <td align="center" width="50%">Saldo caixa</td>
                                        <td align="center" width="50%">${self.formatCoin(self.checkBalance(salesman.total_apuracao, salesman.total_premios, salesman.total_comissao, salesman.total_comissao_ao_vivo, salesman.retiradas, salesman.lancamentos))}</td>
                                    </tr>
                                </tbody>
                            </table>`;
                        }
                    }
                }

                if (!html) {
                    Swal.fire(
                        'Falha',
                        'Nenhum relatório disponível para a opção escolhida!',
                        'error'
                    );
                } else {

                    self.isLoading = true;

                    iframe.width = '100%';
                    iframe.height = '100%';
                    iframe.src = 'about:blank';
                    iframe.style.visibility = 'hidden';

                    document.body.appendChild(iframe);

                    iframe.contentWindow.document.write(`
                        <html>
                            <head>
                                <style>*{margin:0;padding:0}html{width:100%;height:100%;}body{padding:20px;background-color:#fff;color:#000;font-family:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";}.table{width:100%;border-collapse:collapse}.table{margin-bottom:10px}.table td {padding:10px}</style>
                            </head>
                            <body class="content">
                                ${html}
                            </body>
                        </html>
                    `);

                    setTimeout(() => {

                        let pWidth = doc.internal.pageSize.width;
                        let srcWidth = iframe.contentWindow.document.querySelector('.content').scrollWidth;
                        let margin = 18; // narrow margin - 1.27 cm (36);
                        let scale = (pWidth - margin * 2) / srcWidth;

                        doc.html(html, {
                            callback: function (doc) {
                                self.isLoading = false;
                                iframe.remove();
                                // doc.output("dataurlnewwindow");
                                doc.save(`${Math.floor(Math.random() * 1000000)}.pdf`);
                            },
                            x: margin,
                            y: margin,
                            width: srcWidth,
                            windowWidth: srcWidth,
                            html2canvas: {
                                scale: scale,
                                width: srcWidth
                            },
                        });
                    }, 100);
                }
            }
        },
        savePDF(id) {

            const self = this;
            const date1 = moment(self.date1).format('DD/MM/YYYY');
            const date2 = moment(self.date2).format('DD/MM/YYYY');

            try {

                const doc = new jsPDF("p", "mm", "a4");
                const iframe = document.createElement('iframe');
                const salesman = self.salesman.find((item) => item.id == id);

                const html = `
                    <html>
                        <head>
                            <style>*{margin:0;padding:0}html{width:100%;height:100%}body{padding:20px;background:#fff;color:#000;font-family:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";}.table{width:100%;border-collapse:collapse}.table td {padding:10px}</style>
                        </head>
                        <body class="content">
                        <br /> 
                            <center><strong>Relatório</strong> <br /> Do dia ${date1} até ${date2}</center>
                        <br />
                        <table class="table" border="1">
                            <tbody>
                                <tr>
                                    <td colspan="12" align="center" bgcolor="#ddd">
                                        <strong>
                                            <p style="margin-bottom:5px">${salesman.nome.toUpperCase()}</p>
                                        </strong>
                                        <hr />
                                        <p>${salesman.endereco}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Apurou</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.total_apuracao)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Qtd. Apostas</td>
                                    <td align="center" width="50%">${self.formatValue(salesman.num_apostas)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Qtd. Ganhadores</td>
                                    <td align="center" width="50%">${self.formatValue(salesman.num_premios)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Qtd. Apostas abertas</td>
                                    <td align="center" width="50%">${self.formatValue(salesman.num_apostas_abertas)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Entradas em aberto</td>
                                    <td align="center" width="50%">${self.formatValue(salesman.total_apostas_abertas)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em prêmios</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.total_premios)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em comissões (pré-jogo)</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.total_comissao)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em comissões (ao-vivo)</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.total_comissao_ao_vivo)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em comissões (geral)</td>
                                    <td align="center" width="50%">${self.formatCoin(parseFloat(salesman.total_comissao) + parseFloat(salesman.total_comissao_ao_vivo))}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em lançamentos</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.lancamentos)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em retiradas</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.retiradas)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo líquido</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.total_apuracao - salesman.total_premios - salesman.total_comissao - salesman.total_comissao_ao_vivo)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo caixa</td>
                                    <td align="center" width="50%">${self.formatCoin(self.checkBalance(salesman.total_apuracao, salesman.total_premios, salesman.total_comissao, salesman.total_comissao_ao_vivo, salesman.retiradas, salesman.lancamentos))}</td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <h5>
                            <center>${document.location.origin}</center>
                        </h5>
                    </body>
                    </html>
                `;

                self.isLoading = true;

                iframe.style.width = '100%';
                iframe.style.height = '100%';
                iframe.src = 'about:blank';
                iframe.style.visibility = 'hidden';         

                document.body.appendChild(iframe);

                iframe.contentWindow.document.write(html);

                setTimeout(() => {

                    let canvas = document.createElement('canvas');
                    let ticket = iframe.contentWindow.document.querySelector('.content');

                    canvas.width = ticket.scrollWidth;
                    canvas.height = ticket.scrollHeight;

                    rasterizeHTML.drawHTML(html, canvas).then(() => {
                        self.isLoading = false;
                        doc.addImage(canvas.toDataURL('image/jpeg'), 'JPEG', 0, 0, 210, 0);
                        doc.save(`${Math.floor(Math.random() * 1000000)}.pdf`);
                        iframe.remove();
                    });
                }, 100);
            } 
            catch(e) 
            {
                Swal.fire(
                    'Falha',
                    'Não foi possível gerar o PDF do relatório!',
                    'error'
                );
            }
        },
        saveImage(id) {

            const self = this;
            const date1 = moment(this.date1).format('DD/MM/YYYY');
            const date2 = moment(this.date2).format('DD/MM/YYYY');

            try {

                const link = document.createElement('a');
                const iframe = document.createElement('iframe');
                const salesman = self.salesman.find((item) => item.id == id);

                const html = `
                    <html>
                        <head>
                            <meta charset="utf-8">
                            <style>*{margin:0;padding:0}html{width:100%;height:100%}body{padding:20px;background:#fff;color:#000;font-family:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"}.table{width:100%;border-collapse:collapse}.table td {padding:10px}</style>
                        </head>
                    <body class="content">
                        <br /> 
                            <center><strong>Relatório</strong> <br /> Do dia ${date1} até ${date2}</center>
                        <br />
                        <table class="table" border="1">
                            <tbody>
                                <tr>
                                    <td colspan="12" align="center" bgcolor="#ddd">
                                        <strong>
                                            <p style="margin-bottom:5px">${salesman.nome.toUpperCase()}</p>
                                        </strong>
                                        <hr />
                                        <p>${salesman.endereco}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Apurou</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.total_apuracao)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Qtd. Apostas</td>
                                    <td align="center" width="50%">${self.formatValue(salesman.num_apostas)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Qtd. Ganhadores</td>
                                    <td align="center" width="50%">${self.formatValue(salesman.num_premios)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Qtd. Apostas abertas</td>
                                    <td align="center" width="50%">${self.formatValue(salesman.num_apostas_abertas)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Entradas em aberto</td>
                                    <td align="center" width="50%">${self.formatValue(salesman.total_apostas_abertas)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em prêmios</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.total_premios)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em comissões (pré-jogo)</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.total_comissao)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em comissões (ao-vivo)</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.total_comissao_ao_vivo)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em comissões (geral)</td>
                                    <td align="center" width="50%">${self.formatCoin(parseFloat(salesman.total_comissao) + parseFloat(salesman.total_comissao_ao_vivo))}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em lançamentos</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.lancamentos)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo em retiradas</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.retiradas)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo líquido</td>
                                    <td align="center" width="50%">${self.formatCoin(salesman.total_apuracao - salesman.total_premios - salesman.total_comissao - salesman.total_comissao_ao_vivo)}</td>
                                </tr>
                                <tr>
                                    <td align="center" width="50%">Saldo caixa</td>
                                    <td align="center" width="50%">${self.formatCoin(self.checkBalance(salesman.total_apuracao, salesman.total_premios, salesman.total_comissao, salesman.total_comissao_ao_vivo, salesman.retiradas, salesman.lancamentos))}</td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <h5>
                            <center>${document.location.origin}</center>
                        </h5>
                    </body>
                    </html>
                `;

                self.isLoading = true;

                iframe.style.width = '600px';
                iframe.style.height = '100%';
                iframe.src = 'about:blank';
                iframe.style.visibility = 'hidden';         

                document.body.appendChild(iframe);

                iframe.contentWindow.document.write(html);

                setTimeout(() => {

                    let canvas = document.createElement('canvas');
                    let ticket = iframe.contentWindow.document.querySelector('.content');

                    canvas.width = ticket.scrollWidth + 20;
                    canvas.height = ticket.scrollHeight + 20;

                    rasterizeHTML.drawHTML(html, canvas).then(() => {
                        self.isLoading = false;
                        link.download = Math.floor(Math.random() * 1000000) + '.jpeg';
                        link.href = canvas.toDataURL('image/jpeg');
                        link.click();
                        iframe.remove();
                    });
                }, 100);
            } 
            catch(e) 
            {
                Swal.fire(
                    'Falha',
                    'Não foi possível gerar a imagem do relatório!',
                    'error'
                );
            }
        },
        async releases() {

            const self = this;
            const datetime = moment().tz('America/Recife').format('YYYY-MM-DD[T]HH:mm');

            const { value: formValues } = await Swal.fire({
                title: 'Novo lançamento',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Lançar',
                html: `
                    <label class="d-block text-left mb-0" for="swal-input1">Vendedor</label>
                    <input type="hidden" id="swal-input1" value="" />
                    <div id="swal-virtual-select" data-style="swall"></div>
                    <div id="swal-append"></div>

                    <label class="d-block text-left mb-0" for="swal-input2">Tipo de lançamento</label>
                    <select id="swal-input2" class="swal2-input w-100">
                        <option value="1">Enviar dinheiro</option>
                        <option value="2">Retirar dinheiro</option>
                    </select>

                    <label class="d-block text-left mb-0" for="swal-input3">Valor do lançamento</label>
                    <input id="swal-input3" class="swal2-input" type="text" placeholder="Ex: 100">

                    <label class="d-block text-left mb-0" for="swal-input4">Descrição do lançamento</label>
                    <input id="swal-input4" class="swal2-input" type="text" placeholder="Ex: Pagamento da premiação">

                    <label class="d-block text-left mb-0" for="swal-input5">Horário do lançamento</label>
                    <input id="swal-input5" class="swal2-input" value="${datetime}" type="datetime-local">
                `,
                customClass: {
                    actions: 'swal2-actions swal-virtual-select'
                },
                focusConfirm: false,
                preConfirm: () => {
                    return {
                        id: document.getElementById('swal-input1').value,
                        tipo: document.getElementById('swal-input2').value,
                        valor: document.getElementById('swal-input3').value,
                        descricao: document.getElementById('swal-input4').value,
                        horario: document.getElementById('swal-input5').value,
                    }
                },
                onOpen: () => {

                    const options = [];
                    const users = self.salesman.sort((a, b) => {
                        return a.nome.localeCompare(b.nome);
                    });

                    const total_users = users.length;

                    if (total_users) {
                        for (const user of users) {
                            options.push({
                                value: user.id,
                                label: user.nome,
                                description: user.endereco
                            });
                        }
                    }

                    window.VirtualSelect.init({
                        ele: '#swal-virtual-select',
                        search: true,
                        placeholder: `Selecione um vendedor`,
                        noOptionsText: 'Nenhuma opção disponível',
                        noSearchResultsText: 'Nenhum resultado encontrado',
                        searchPlaceholderText: `Buscar vendedor (${total_users})...`,
                        hasOptionDescription: true,
                        options: options
                    });

                    if (document.querySelector('#swal-virtual-select')) {
                        document.querySelector('#swal-virtual-select').addEventListener('change', function() {
                            document.getElementById('swal-input1').value = this.value;
                        });
                    }

                    document.getElementById("swal-input3").addEventListener("input", (e) => {
                        let value = e.target.value;
                        value = value.replace(/\D/g,"");  
                        value = value.replace(/[0-9]{12}/," ");  
                        value = value.replace(/(\d{1})(\d{8})$/,"$1$2");
                        value = value.replace(/(\d{1})(\d{5})$/,"$1$2");  
                        value = value.replace(/(\d{1})(\d{1,2})$/,"$1.$2"); 
                        return e.target.value = value;
                    });
                }
            });

            if (!formValues) {
                return false;
            } else if (formValues.id == '') {
                Swal.fire(
                    'Atenção!',
                    'Selecione um vendedor antes de continuar!',
                    'warning'
                );
            } else if (formValues.valor == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe o valor do lançamento!',
                    'warning'
                );
            } else if (formValues.descricao == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe a descrição do lançamento!',
                    'warning'
                );
            } else if (formValues.horario == '') {
                Swal.fire(
                    'Atenção!',
                    'Informe o horário do lançamento!'
                );
            } else {

                const nome = self.salesman.find((item) => item.id == formValues.id).nome;

                Swal.fire({
                    title: 'Confirmar lançamento',
                    html: `Você deseja mesmo enviar um lançamento para o vendendor "<b>${nome}</b>" ?`,
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Cancelar',
                    confirmButtonText: 'Sim'
                }).then((result) => {

                    if (result.value) {
                        
                        self.isLoading = true;

                        api.post('dashboard/releases', {
                            form: formValues
                        }).then((response) => {
                            switch(response.data.result) {
                                case 'success':

                                    self.updateSalesmanReleasesByID(formValues.id, response.data.retiradas, response.data.lancamentos);

                                    Swal.fire(
                                        'Parabéns!',
                                        'O lançamento foi realizado com sucesso!',
                                        'success'
                                    );
                                break;
                                default:
                                    Swal.fire(
                                        'Falha',
                                        response.data.message,
                                        'error'
                                    );
                            }
                        }).catch((error) => {
                            try {
                                Swal.fire(
                                    'Falha',
                                    self.network_erros[error.status]['message'],
                                    'error'
                                );
                            } catch(e) {
                                Swal.fire(
                                    'Falha',
                                    self.network_erros[408]['message'],
                                    'error'
                                );
                            }
                        }).finally(() => {
                            self.isLoading = false;
                        });
                    }
                });
            }
        },
        updateSalesman() {
            for (let index in this.salesman) {
                this.salesman[index].caixa = 0;
                this.salesman[index].retiradas = 0;
                this.salesman[index].lancamentos = 0;
            }
        },
        updateSalesmanByID(id, value) {

            const new_value = value.toString().replace(/[^0-9/.]+/g, '');
            const index = this.salesman.findIndex((item) => item.id == id);

            if (index != -1) {

                const salesman = this.salesman[index];

                if (value < 0) {
                    salesman.lancamentos += parseFloat(new_value);
                } else {
                    salesman.retiradas += parseFloat(new_value);
                }
            }
        },
        updateSalesmanReleasesByID(id, retiradas, lancamentos) {

            const index = this.salesman.findIndex((item) => item.id == id);

            if (index != -1) {
                this.salesman[index].retiradas = retiradas;
                this.salesman[index].lancamentos = lancamentos;
            }
        },
        searchUser(event) {
            this.searchs = event.target.value;
        },
        checkValue(value) {
            return value.toString() == '-0' ? 0 : value;
        },
        positiveOrNegative(value) {
            return value < 0 ? 'text-danger' : value == 0 ? 'text-black' : 'text-success';
        },
        checkBalance(total_apuracao, total_premios, total_comissao, total_comissao_ao_vivo, retiradas, lancamentos) {
            return parseFloat(((parseFloat(total_apuracao) - parseFloat(total_premios) - parseFloat(total_comissao) - parseFloat(total_comissao_ao_vivo)) + parseFloat(lancamentos)) - parseFloat(retiradas));
        },
        formatDate(date) {
            return moment(date).tz('America/Recife').format('DD/MM/YYYY');
        },
        formatValue(nStr) {
            nStr += '';
            let x = nStr.split('.');
            let x1 = x[0];
            let x2 = x.length > 1 ? '.' + x[1] : '';
            let rgx = /(\d+)(\d{3})/;
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + '.' + '$2');
            }
            return x1 + x2;
        },
        formatCoin(n, c, d, t, s, i, j) {
            c = isNaN(c = Math.abs(c)) ? 2 : c, d = d == undefined ? "," : d, t = t == undefined ? "." : t, s = n < 0 ? "-" : "", i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "", j = (j = i.length) > 3 ? j % 3 : 0;
            return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
        },
        accountabilityReleased() {

            var date = moment(this.date1).add(6, 'days').format('YYYY-MM-DD');

            if (date == this.date2) {
                this.can_close_box_disabled = false;
            } else {
                this.can_close_box_disabled = true;
            }            
        },
        removeAccents(str) {
            
            let string = str;
            let map = {a : /[\xE0-\xE6]/g, A : /[\xC0-\xC6]/g, e : /[\xE8-\xEB]/g, E : /[\xC8-\xCB]/g, i : /[\xEC-\xEF]/g, I : /[\xCC-\xCF]/g, o : /[\xF2-\xF6]/g, O : /[\xD2-\xD6]/g, u : /[\xF9-\xFC]/g, U : /[\xD9-\xDC]/g, c : /\xE7/g, C : /\xC7/g, n : /\xF1/g, N : /\xD1/g};

            for (let key in map) {
                 let rex = map[key];
                 string = string.replace(rex, key);
            }

            return string;
        },
        filterAz(filter) {

            const options = [];
            const select = document.querySelector('#virtual-select-salesman');

            options.push({
                value: '',
                label: 'Todos',
                description: 'Exibe o relatório de todos'
            });

            if (filter == 'all') {

                this.filter_az_active = '';

                for (const item of this.salesman) {
                    options.push({
                        label: item.nome,
                        value: item.usuario,
                        description: item.endereco
                    });
                }

                select.setOptions(options);
            } 
            else 
            {
                this.filter_az_active = filter;
                const users = this.salesman.filter((item) => this.removeAccents(item.nome).toLowerCase().substr(0, 1) == filter);

                if (users.length == 0) {
                    Swal.fire(
                        'Atenção!',
                        `Não encontramos nenhum vendedor com a letra inicial <strong class="text-uppercase">${filter}</strong>.`,
                        'warning'
                    );
                } else {

                    for (const item of users) {
                        options.push({
                            label: item.nome,
                            value: item.usuario,
                            description: item.endereco
                        });
                    }

                    select.setOptions(options);
                }
            }
        },
        async selectDialog(value) {

            const { value: formValues } = await Swal.fire({
                title: 'Relatório',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'OK',
                html: `
                    <select id="swal-input1" class="swal2-input w-100">
                        <option value="1">Imprimir</option>
                        <option value="2">Salvar PDF</option>
                    </select>
                `,
                focusConfirm: false,
                preConfirm: () => {
                    return {
                        tipo: document.getElementById('swal-input1').value,
                    }
                },
            });

            if (formValues) {
                
                if (formValues.tipo == 1) {
                    this.printReportByManager(value);
                } else {
                    this.pdfReportByManager(value);
                }
            }
        },
        overLapToggle() {
            this.overLap = !this.overLap;
        }
    },
    filters: {
        formatCoin(n, c, d, t, s, i, j) {
            c = isNaN(c = Math.abs(c)) ? 2 : c, d = d == undefined ? "," : d, t = t == undefined ? "." : t, s = n < 0 ? "-" : "", i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "", j = (j = i.length) > 3 ? j % 3 : 0;
            return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
        },
        formatValue(nStr) {
            nStr += '';
            let x = nStr.split('.');
            let x1 = x[0];
            let x2 = x.length > 1 ? '.' + x[1] : '';
            let rgx = /(\d+)(\d{3})/;
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + '.' + '$2');
            }
            return x1 + x2;
        }
    },
    watch: {
        users(items) {
            
            const options = [];
            const total_items = items.length;
            const select = document.querySelector('#virtual-select');

            if (total_items && select) {

                if (total_items > 1) {
                    options.push({
                        value: 'all',
                        label: 'Todos',
                        description: 'Exibe o relatório de todos'
                    });
                }

                for (const item of items) {

                    this.dbUsers[item._id] = {
                        gerente_id: item.gerente_id,
                        credito_limite: parseFloat(item.credito_limite), 
                        credito_simples_limite: parseFloat(item.credito_simples_limite)
                    };

                    options.push({
                        value: item._id,
                        label: item.nome,
                        description: item.endereco
                    });
                }

                select.setOptions(options);
            }
        },
        salesman(items) {

            const options = [];
            const total_items = items.length;
            const select = document.querySelector('#virtual-select-salesman');

            if (total_items && select) {

                if (total_items > 1) {
                    options.push({
                        value: '',
                        label: 'Todos',
                        description: 'Exibe o relatório de todos'
                    });
                }

                for (const item of items) {
                    options.push({
                        label: item.nome,
                        value: item.usuario,
                        description: item.endereco
                    });
                }

                select.setOptions(options);
            }
        }
    },
    computed: {
        filteredListSalesman() {
            return this.salesman.filter((item) => {
                return item.nome.toLowerCase().trim().includes(this.searchs.toLowerCase().trim()) || item.usuario.toLowerCase().trim().includes(this.searchs.toLowerCase().trim())
            });
        }
    },
    beforeMount() {
        try {
            const {pipe} = JSON.parse(window.localStorage.getItem('auth'));
            this.pipe = pipe;
        } catch(e) {
            this.pipe = 0;
        }
    },
    mounted() {

        const self = this;

        window.VirtualSelect.init({
            ele: '#virtual-select',
            search: true,
            placeholder: 'Selecione um vendedor',
            noOptionsText: 'Nenhuma opção disponível',
            noSearchResultsText: 'Nenhum resultado encontrado',
            searchPlaceholderText: 'Buscar vendedor...',
            hasOptionDescription: true
        });

        window.VirtualSelect.init({
            ele: '#virtual-select-salesman',
            search: true,
            placeholder: 'Selecione um vendedor',
            noOptionsText: 'Nenhuma opção disponível',
            noSearchResultsText: 'Nenhum resultado encontrado',
            searchPlaceholderText: 'Buscar vendedor...',
            hasOptionDescription: true
        });

        if (document.querySelector('#virtual-select')) {
            document.querySelector('#virtual-select').addEventListener('change', function() {
                if (this.value) {
                    self.user = this.value;
                } else {
                    self.user = 'all';
                    let vscompValue = document.querySelector('#virtual-select .vscomp-value');
                    if (vscompValue) {
                        vscompValue.innerText = 'Todos';
                    }
                }
            });
        }

        if (document.querySelector('#virtual-select-salesman')) {
            document.querySelector('#virtual-select-salesman').addEventListener('change', function() {
                
                self.overLap = false;

                if (this.value) {
                    self.searchs = this.value;
                } else {
                    self.searchs = '';
                    let vscompValue = document.querySelector('#virtual-select-salesman .vscomp-value');
                    if (vscompValue) {
                        vscompValue.innerText = 'Todos';
                    }
                }
            });
        }
    },
    created() {

        const self = this;

        self.loading = true;
        self.nothing_found = false;

        api.get('dashboard/type/users/1').then((response) => {
            switch(response.data.result) {
                case 'success':
                    self.launch = response.data.fazer_lancamento;
                    self.users = response.data.users.sort((a, b) => {
                        return self.removeAccents(a.nome).localeCompare(self.removeAccents(b.nome), 'fr', {
                            ignorePunctuation: true
                        });
                    });
                break;
                case 'nothing_found':
                    self.nothing_found = true;
                break;
                default:
                    self.error = 'Ocorreu um erro, tente novamente!';
                break;
            }
        }).catch((error) => {
            try {
                self.error = self.network_erros[error.status]['message'];
            } catch(e) {
                self.error = self.network_erros[408]['message'];
            }
        }).finally(() => {
            self.loading = false;
        });

        window.addEventListener('resize', () => {
            self.platform = this.isMobile();
        });
    }
}
</script>

<style scoped>
.main {
    margin-top: 80px;
}
.d-contents {
    display: contents!important;
}
.col.pl-0 #virtual-select {
    max-width: 100%!important;
}
.collection-cluster {
    overflow-x: auto;
}
.collection-cluster:not(.desktop) {
    margin-top: 132px;
}
.collection-cluster.desktop::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}
.collection-cluster.desktop::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
.collection-cluster.desktop::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: 0px none #ffffff;
  border-radius: .25rem;
}
.collection-cluster.desktop::-webkit-scrollbar-thumb:hover {
  background: #ffffff;
}
.collection-cluster.desktop::-webkit-scrollbar-thumb:active {
  background: #ddd;
}
.collection-cluster.desktop::-webkit-scrollbar-track {
  background: #666666;
  border: 0px none #ffffff;
  border-radius: .25rem;
}
.collection-cluster.desktop::-webkit-scrollbar-track:hover {
  background: #666666;
}
.collection-cluster.desktop::-webkit-scrollbar-track:active {
  background: #333333;
}
.collection-cluster.desktop::-webkit-scrollbar-corner {
  background: transparent;
}
.overlap {
    width: 100%;
    height: 100%;
}
.overlap.overlap-fixed {
    position: fixed;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    background-color: #333;
    right: 0;
    left: 0;
    top: 69px;
    z-index: 1;
}
.overlap.overlap-fixed.overlap-hide {
    height: auto!important;
}
.overlap.overlap-fixed.overlap-hide .overlap-content {
    display: none!important;;
}
</style>